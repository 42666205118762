<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Link, router } from '@inertiajs/vue3'
import { Product } from '@interfaces/product.interface'
import { useCartStore } from '@/Stores/cart.store'
import { EyeIcon, ShoppingBagIcon, HeartIcon } from '@heroicons/vue/20/solid'

const props = withDefaults(defineProps<{
    product: Product
    nameTag?: string
}>(), {
    nameTag: 'h3',
})

const { t, n } = useI18n()
const cartStore = useCartStore()

const priceRange = computed(() => {
    let min = 0
    let max = 0

    if (props.product.variants) {
        min = Math.min(...props.product.variants!.map((item) => item.final_price))
        max = Math.max(...props.product.variants!.map((item) => item.final_price))
    }

    if (!isFinite(min)) {
        min = 0
    }

    if (!isFinite(max)) {
        max = 0
    }
    const minFormatted = n(min, 'currency')

    if (!max) {
        return minFormatted
    }

    const maxFormatted = n(max, 'currency')

    if (minFormatted !== maxFormatted) {
        return `${minFormatted} - ${maxFormatted}`
    }

    return minFormatted
})

// const previewProduct = () => {
//     router.visit(route('product', props.product.slug))
// }

// const addToCart = () => {
//     previewProduct()
// }
</script>

<template>
    <!-- //     @php
//     // $isGroup = $product->group && $product->group->combined_group;
//     $displayProduct = $product;

//     // if ($isGroup && ! $product->in_stock) {
//     //     $groupProduct = $product->group->products
//     //         ->filter(function ($product) {
//     //             return $product->in_stock;
//     //         })
//     //         // ->sortDesc('bought_quantity')
//     //         ->values()
//     //         ->first();

//     //     if ($groupProduct) {
//     //         $displayProduct = $groupProduct;
//     //     }
//     // }

//     $url = route('product', $product->slug_path);
// @endphp -->

    <Link :href="route('product', product.slug)" class="relative block group">
    <span v-if="product.is_new && !product.is_sale && (product.in_stock || (product.variants && product.variants?.length > 0 && product.variants?.reduce((amount, { quantity }) => amount + quantity, 0) > 0))" class="absolute left-3 top-3 z-10 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">{{ t('components.product.new') }}</span>
    <span v-if="product.is_sale && product.in_stock" class="absolute right-3 top-3 z-10 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">{{ t('components.product.discount') }} -{{ product.sale_percent }}%</span>
    <span
        v-if="(!product.in_stock && product.variants?.length === 0)
            || (product.variants && product.variants?.length > 0 && product.variants?.reduce((amount, { quantity }) => amount + quantity, 0) === 0)
            "
        class="absolute right-3 top-3 z-10 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-200 text-slate-800">
        {{ t('components.product.out-of-stock') }}
    </span>

    <div class="relative">
        <div class="relative w-full overflow-hidden bg-gray-200 rounded-lg aspect-w-7 aspect-h-8 xl:aspect-w-7 xl:aspect-h-8">
            <picture>
                <source :data-srcset="product.image_card_webp_url" type="image/webp">
                <source :data-srcset="product.image_card_url" type="image/jpeg">

                <img class="object-cover object-center w-full h-full lazyload" :data-src="product.image_card_url" :alt="product.title">
            </picture>

            <div class="absolute inset-0 bg-black bg-opacity-[0.02] transition-colors group-hover:bg-opacity-[0.04]"></div>
        </div>

        <!-- <div class="absolute justify-center hidden w-full md:flex bottom-5 gap-x-2">
            <div class="transition duration-100 ease-in-out opacity-[0] translate-y-2 group-hover:!opacity-100 group-hover:translate-y-0">
                <button class="flex items-center justify-center w-10 h-10 transition-colors bg-white rounded-full text-slate-900 hover:bg-gray-700 hover:text-white" aria-label="View product" @click="previewProduct">
                    <EyeIcon class="w-4 h-4" />
                </button>
            </div>

            <div class="transition duration-100 ease-in-out opacity-[0] z-10 translate-y-2 delay-75 group-hover:!opacity-100 group-hover:translate-y-0">
                <button class="flex items-center justify-center w-10 h-10 transition-colors bg-white rounded-full text-slate-900 hover:bg-gray-700 hover:text-white" aria-label="Add to cart" @click.prevent="addToCart">
                    <ShoppingBagIcon class="w-4 h-4" />
                </button>
            </div>

            <div class="transition duration-100 ease-in-out opacity-[0] translate-y-2 delay-150 group-hover:!opacity-100 group-hover:translate-y-0">
                <button class="flex items-center justify-center w-10 h-10 transition-colors bg-white rounded-full text-slate-900 hover:bg-gray-700 hover:text-white" aria-label="Add to wishlist">
                    <HeartIcon class="w-4 h-4" />
                </button>
            </div>
        </div> -->
    </div>

    <component :is="nameTag" class="mt-4 text-sm text-slate-700">{{ product.title }}</component>
    <div class="mt-1 text-lg font-medium text-slate-900">
        <template v-if="product.variants && product.variants?.length > 0">
            {{ priceRange }}
        </template>

        <template v-else-if="product.is_sale">
            <span class="text-sm line-through text-slate-500">{{ n(product.retail_price, 'currency') }}</span>
            <span class="text-red-700">{{ n(product.sale_price, 'currency') }}</span>
        </template>

        <template v-else>
            <span>{{ n(product.retail_price, 'currency') }}</span>
        </template>
    </div>
    </Link>
</template>
